.dropzone {
  border-width: 2px;
  border-radius: 2px;
  border-color: #90caf9;
  border-style: dashed;
  background-color: #fafafa;
  outline: none;
  transition: border .24s;
  display: flex;
  align-items: center;
  width: 400px;
  height: 40px;
}

.dropzone:hover {
  background-color: #eeeeee;
}

.hide {
  display: none;
  color: black;
}

.dropzone:hover .hide {
  display: block;
  color: #616161;
}

.dropzone div {
    width: 100%;
}


